<template>
  <div>
    <div>
      <h3>{{ $t("settings") }}</h3>
      <p>
        {{ $t("edit_information") }}
      </p>

      <validation-observer
        ref="companyRegisterValidation"
        name="RegisterCompany"
      >
        <b-row class="col-md-12 pr-0 mr-0" v-if="errors.length > 0">
          <div class="alert alert-danger col-md-12 pr-0">
            <div class="p-2">
              <div v-for="(err, index) in errors" :key="index">
                <b-avatar variant="light-danger" size="45">
                  <feather-icon size="21" icon="AlertTriangleIcon" />
                </b-avatar>
                {{ err }}
              </div>
            </div>
          </div>
        </b-row>
        <b-form @submit="onSubmit" @reset="onReset">
          <b-card>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="domicile-country-group"
                  :label="$t('domicile_country') + ':'"
                  label-for="domicile-country"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Country"
                    rules="required"
                  >
                    <b-form-select
                      id="domicile-country"
                      v-model.trim="country_id"
                      :options="
                        $store.state.app.language == 'en'
                          ? countries
                          : countries_de
                      "
                      :state="errors.length > 0 ? false : null"
                      required
                      :disabled="disableInputs"
                    ></b-form-select>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  id="company-name-group"
                  :label="$t('company_name') + ':'"
                  label-for="company-name"
                >
                  <b-input-group>
                    <template #append>
                      <b-button
                        @click="getCompanyDataFromZefix()"
                        v-if="companies[company_name] != null"
                      >
                        <feather-icon icon="DownloadIcon" /> Get Data
                      </b-button>
                    </template>
                    <b-form-input
                      autocomplete="off"
                      @keyup="searchForCompaniesName()"
                      id="company-name"
                      v-model.trim="company_name"
                      required
                      list="companies-list"
                      disabled
                    ></b-form-input>
                  </b-input-group>
                  <div class="mt-2">
                    {{ $t("company_uid") }}: "{{ company_uid }}"
                  </div>
                  <datalist id="companies-list">
                    <option v-for="(company, index) in companies" :key="index">
                      {{ index }}
                    </option>
                  </datalist>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <img src="/new-loader.svg" v-if="showLoader" />
                <!-- {{ JSON.stringify(company_zefix_data) }} -->
              </b-col>
            </b-row>
          </b-card>
          <b-card>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="company-name-field-group"
                  :label="$t('company_name') + ':'"
                  label-for="company-name-field"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company Name"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HomeIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="company-name-field"
                        v-model.trim="company_name"
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                      ></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="4" md="4" lg="4">
                <b-form-group
                  id="company-address-group"
                  :label="$t('company_address') + ':'"
                  label-for="company-address"
                  prepend="@"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company Address"
                    rules="required"
                  >
                    <b-form-input
                      id="company-address"
                      v-model.trim="company_address"
                      :state="errors.length > 0 ? false : null"
                      required
                      :disabled="disableInputs"
                    >
                    </b-form-input>

                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="2" md="2" lg="2">
                <b-form-group
                  id="company-address-nr-group"
                  :label="$t('company_address_nr') + ':'"
                  label-for="company-address-nr"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company Address Nr"
                    rules="required"
                  >
                    <b-form-input
                      id="company-address-nr"
                      v-model.trim="company_address_nr"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                    ></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="company-uid-group"
                  :label="
                    $t('company_uid') + '(CHE-397.517.219 > CHE397517219):'
                  "
                  label-for="company-uid"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company UID"
                    rules="required"
                  >
                    <b-form-input
                      id="company-uid"
                      v-model.trim="company_uid"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                    ></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="2" md="2" lg="2">
                <b-form-group
                  id="company-zip-group"
                  :label="$t('zip') + ':'"
                  label-for="company-zip"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company Zip"
                    rules="required"
                  >
                    <b-form-input
                      id="company-zip"
                      v-model.trim="company_zip"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                    ></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="4" md="4" lg="4">
                <b-form-group
                  id="company-city-group"
                  :label="$t('city') + ':'"
                  label-for="company-city"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company City"
                    rules="required"
                  >
                    <b-form-input
                      id="company-city"
                      v-model.trim="company_city"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                    ></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="company-legal-form-group"
                  :label="$t('legal_form') + ':'"
                  label-for="company-legal-form"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Company Legal Form"
                    rules="required"
                  >
                    <b-form-select
                      id="company-legal-form"
                      v-model.trim="company_legal_form"
                      :options="[
                        { value: 0, text: $t('sole_proprietorship') },
                        { value: 1, text: $t('general_partnership') },
                        { value: 2, text: $t('limited_partnership') },
                        { value: 3, text: $t('corporation') },
                        { value: 4, text: $t('limited_liability_company') },
                        { value: 5, text: $t('cooperative') },
                        { value: 6, text: $t('cantonal_public_company')},
                        { value: 7, text: $t('association')}
                       ]"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                    ></b-form-select>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="company-website-group"
                  :label="$t('website') + ':'"
                  label-for="company-website"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="GlobeIcon" class="cursor-pointer" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="company-website"
                      v-model.trim="company_website"
                      required
                      :disabled="disableInputs"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col> -->
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="language_of_correspondence'"
                  :label="$t('language_of_correspondence') + ':'"
                  label-for="language_of_correspondence'"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-select
                      :options="[
                        { text: $t('german'), value: 'de' },
                        { text: $t('english'), value: 'en' },
                      ]"
                      id="language_of_correspondence"
                      :disabled="disableInputs"
                      v-model.trim="language"
                      required
                    ></b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <h5>{{ $t("financial_data") }}</h5>
          <b-card>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="company-established-at-group"
                  :label="$t('established_since') + ':'"
                  label-for="company-established-at-field"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Established since"
                    rules="required"
                  >
                    <b-form-datepicker
                      no-flip
                      id="company-established-at-field"
                      v-model.trim="company_established_at"
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                      required
                    ></b-form-datepicker>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="company-number-of-employees-group"
                  :label="$t('number_of_employees') + ':'"
                  label-for="company-number-of-employees"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Number of employees"
                    rules="required"
                  >
                    <b-form-input
                      type="number"
                      id="company-number-of-employees"
                      v-model.trim="number_of_employees"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                    ></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="company-annual-turnover-group"
                  :label="$t('annual_turnover') + ':'"
                  label-for="company-annual-turnover-field"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Annual turnover"
                    rules="required"
                  >
                    <b-form-input
                      id="company-annual-turnover-field"
                      v-model.trim="annual_turnover"
                      required
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                    ></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  :label="$t('open_debt_enforcement_against_you') + '?'"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    :disabled="disableInputs"
                    id="company-debt-enforcement"
                    v-model.trim="debt_enforcement"
                    :options="debt_enforcement_values"
                    :aria-describedby="ariaDescribedby"
                    name="debt-enforcement"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>
              <!-- <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="company-intent-to-sell-group"
                  :label="$t('how_much_do_you_intend_to_sell') + '?'"
                  label-for="company-intent-to-sell"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Intend to sell"
                    rules="required"
                  >
                    <b-form-select
                      id="company-intent-to-sell"
                      v-model.trim="intent_to_sell"
                      :options="intent_to_sell_values"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                    ></b-form-select>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
            </b-row>
            <b-row m-0 p-0>
              <!-- <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="company-number-of-debtors-group"
                  :label="$t('total_number_of_debtors') + ':'"
                  label-for="company-number-of-debtors"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Number of Debtors"
                    rules="required"
                  >
                    <b-form-select
                      id="company-number-of-debtors"
                      v-model.trim="number_of_debtors"
                      :options="number_of_debtors_values"
                      required
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                    ></b-form-select>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
            
            </b-row>
          </b-card>
          <h5>{{ $t("main_contact_person") }}</h5>
          <b-card>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="main-contact-gender"
                  :label="$t('salutation') + ':'"
                  label-for="main-contact-gender"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="main-contact-gender"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-select
                        id="main-contact-gender"
                        :disabled="disableInputs"
                        v-model.trim="main_contact_gender"
                        :options="[
                          {
                            text: $t('select_one'),
                            value: null,
                            disabled: true,
                          },
                          { text: $t('mr'), value: '0' },
                          { text: $t('mrs'), value: '1' },
                        ]"
                        required
                        :state="errors.length > 0 ? false : null"
                      ></b-form-select>
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="main-contact-email-group"
                  :label="$t('company_email_address') + ':'"
                  label-for="main-contact-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Number of Debtors"
                    rules="required|email"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MailIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="main-contact-email"
                        v-model.trim="main_contact_email"
                        required
                        disabled
                        :state="errors.length > 0 ? false : null"
                      ></b-form-input>
                    </b-input-group>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="main-contact-first-name-group"
                  :label="$t('first_name') + ':'"
                  label-for="main-contact-first-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="First name"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="main-contact-first-name"
                        v-model.trim="main_contact_first_name"
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                      ></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="main-contact-tel-group"
                  :label="$t('mobile') + ':'"
                  label-for="main-contact-tel"
                >
                  <validation-provider
                    ref="phoneValidator"
                    #default="{ errors }"
                    name="phoneValidation"
                    rules="required"
                  >
                    <b-input-group>
                      <b-input-group-prepend
                        class="phoneCountries"
                        id="phoneCountries"
                      >
                        <b-form-select
                          :options="phoneList"
                          v-model.trim="phoneContries"
                        >
                        </b-form-select>
                      </b-input-group-prepend>
                      <b-form-input
                        id="main-contact-tel"
                        v-model.trim="main_contact_tel"
                        @input="checkPhoneFormat()"
                        class="form-control"
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                      >
                      </b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                    <small class="text-danger" v-if="phoneValidation">{{
                      phoneValidation
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="main-contact-last-name-group"
                  :label="$t('last_name') + ':'"
                  label-for="main-contact-last-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="main-contact-last-name"
                        v-model.trim="main_contact_last_name"
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                      ></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="main-contact-position-group"
                  :label="$t('position_in_the_company') + ':'"
                  label-for="main-contact-position"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Position in the Company"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon
                          icon="BriefcaseIcon"
                          class="cursor-pointer"
                        />
                      </b-input-group-prepend>
                      <b-form-input
                        id="main-contact-position"
                        v-model.trim="main_contact_position"
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                      ></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  id="signing-authority-type-group"
                  :label="$t('salutation') + ':'"
                  label-for="signing-authority-type"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="signing-authority-type-group"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon
                          icon="UserPlusIcon"
                          class="cursor-pointer"
                        />
                      </b-input-group-prepend>
                      <b-form-select
                        :disabled="disableInputs"
                        id="signing-authority-type"
                        v-model.trim="signing_authority_type"
                        @change="hideSignersPart"
                        :options="[
                          {
                            text: $t('select_one'),
                            value: null,
                            disabled: true,
                          },
                          { text: $t('no_signatory'), value: '2' },
                          { text: $t('sole_signatory'), value: '0' },
                          { text: $t('collectively_by_two'), value: '1' },
                        ]"
                        required
                        :state="errors.length > 0 ? false : null"
                      ></b-form-select>
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <h5 v-if="signators.length > 0">{{ $t("other_signers") }}</h5>
          <b-card
            style="background: #fefefe"
            v-for="(signator, index) in signators"
            :key="index"
          >
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  :id="index + 'signator-gender-group'"
                  :label="$t('salutation') + ':'"
                  :label-for="index + 'signator-gender'"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UserIcon" class="cursor-pointer" />
                    </b-input-group-prepend>
                    <b-form-select
                      :id="index + 'signator-gender'"
                      v-model.trim="signator.gender"
                      :options="[
                        {
                          text: $t('select_one'),
                          value: null,
                          disabled: true,
                        },
                        { text: $t('mr'), value: '0' },
                        { text: $t('mrs'), value: '1' },
                      ]"
                      required
                      :disabled="disableInputs"
                    ></b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  :id="index + 'signator-email-group'"
                  :label="$t('company_email_address') + ':'"
                  :label-for="index + 'signator-email'"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="index + ' Email'"
                    rules="required|email"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MailIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input
                        :id="index + 'signator-email'"
                        v-model.trim="signator.email"
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                      ></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  :id="index + 'signator-first-name-group'"
                  :label="$t('first_name') + ':'"
                  :label-for="index + 'signator-first-name'"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="index + ' First Name'"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input
                        :id="index + 'signator-first-name'"
                        v-model.trim="signator.name"
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                      ></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  :id="index + 'signator-tel-group'"
                  :label="$t('mobile') + ':'"
                  :label-for="index + 'signator-tel'"
                >
                  <b-input-group class="">
                    <b-input-group-prepend
                      class="phoneCountries"
                      id="phoneCountries"
                    >
                      <b-form-select
                        :options="phoneList"
                        v-model.trim="signator.iso_code"
                      >
                      </b-form-select>
                    </b-input-group-prepend>
                    <b-form-input
                      :id="index + 'signator-tel'"
                      v-model.trim="signator.tel"
                      @input="
                        checkPhoneFormatSignator(
                          signator.tel,
                          signator.iso_code
                        )
                      "
                      class="form-control"
                      :disabled="disableInputs"
                    >
                    </b-form-input>
                  </b-input-group>
                  <span v-if="phoneSignatorValidation" class="text-danger">{{
                    phoneSignatorValidation
                  }}</span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  :id="index + 'signator-last-name-group'"
                  :label="$t('last_name') + ':'"
                  :label-for="index + 'signator-last-name'"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="index + ' Last Name'"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon" class="cursor-pointer" />
                      </b-input-group-prepend>
                      <b-form-input
                        :id="index + 'signator-last-name'"
                        v-model.trim="signator.last_name"
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                      ></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  :id="index + 'signator-position-group'"
                  :label="$t('position_in_the_company') + ':'"
                  :label-for="index + 'signator-position'"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="index + ' Position'"
                    rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon
                          icon="BriefcaseIcon"
                          class="cursor-pointer"
                        />
                      </b-input-group-prepend>
                      <b-form-input
                        :id="index + 'signator-position'"
                        v-model.trim="signator.position"
                        required
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                      ></b-form-input>
                    </b-input-group>

                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="12" xl="6" md="6" lg="6">
                <b-form-group
                  :id="index + 'signator-signing-authority-type-group'"
                  :label="$t('signing_authority_type') + ':'"
                  :label-for="index + 'signator-signing-authority-type'"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon
                        icon="UserPlusIcon"
                        class="cursor-pointer"
                      />
                    </b-input-group-prepend>
                    <b-form-select
                      :id="index + 'signator-signing-authority-type'"
                      v-model.trim="signator.signer.signing_authority_type"
                      :options="[
                        {
                          text: $t('select_one'),
                          value: null,
                          disabled: true,
                        },
                        { text: $t('no_signatory'), value: '2' },
                        { text: $t('sole_signatory'), value: '0' },
                        { text: $t('collectively_by_two'), value: '1' },
                      ]"
                      required
                      :disabled="disableInputs"
                    ></b-form-select>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6" md="6" lg="6" class="text-right pt-2">
                <b-button
                  :disabled="disableInputs"
                  variant="outline-danger"
                  @click="deleteSignator(index, signator)"
                >
                  <feather-icon icon="DeleteIcon"></feather-icon>
                  {{ $t("delete") }}
                </b-button>
              </b-col>
            </b-row>
          </b-card>

          <b-row m-0 p-0 v-if="signators.length < 5 && hideSigners">
            <b-col cols="12" class="text-right">
              <b-button
                :disabled="disableInputs"
                variant="outline-primary"
                @click="addNewSigner()"
              >
                <feather-icon icon="PlusIcon"></feather-icon>
                {{ $t("add_signatory") }}
              </b-button>
            </b-col>
          </b-row>

          <!-- <h5>
            {{ $t("company_bank_information") }}
          </h5>

          <b-card>
            <b-row m-0 p-0>
              <b-col cols="6">
                <b-form-group
                  id="iban-group"
                  :label="$t('iban') + ':'"
                  label-for="iban"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="iban"
                    rules="required"
                  >
                    <b-form-input
                      id="iban"
                      :disabled="disableInputs"
                      v-model.trim="iban"
                      required
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row m-0 p-0>
              <b-col cols="6">
                <b-form-group
                  id="bank_name"
                  :label="$t('bank_name') + ':'"
                  label-for="bank_name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Bank Name"
                    rules="required"
                  >
                    <b-form-input
                      :disabled="disableInputs"
                      id="bank_name"
                      v-model.trim="bank_name"
                      required
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  id="bank_address"
                  :label="$t('bank_address') + ':'"
                  label-for="bank_address"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Bank address"
                    rules="required"
                  >
                    <b-form-input
                      :disabled="disableInputs"
                      id="bank_address"
                      v-model.trim="bank_address"
                      required
                      :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card> -->
          <h5>{{ $t("fee_policy") }}</h5>
          <b-card v-if="!preregistered">
            <b-row m-0 p-0>
              <b-col cols="12">
                <!-- terms-and-conditions -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="terms-and-conditions"
                    rules="required"
                  >
                    <b-form-checkbox
                      id="terms-and-conditions"
                      v-model.trim="data_privacy"
                      name="terms-and-conditions"
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                    >
                      {{ $t("agree_with_policy_seller") }}
                    </b-form-checkbox>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                  <div
                    style="background: #efefef; padding: 10px"
                    class="p-1 mt-1"
                  >
                    <p>
                      {{ $t("agree_with_policy_seller_subtitle") }}

                      <!-- Diese AGB's gelten. Wir machen Sie insbesondere darauf aufmerksam, dass Falschangaben strafrechtlich geahndet werden konnen. Auf folgenden Tatbestande kann die Haftung auf Sie personlich rubergehen. -->
                    </p>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <b-card v-else>
              <b-row m-0 p-0>
                <b-col cols="12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="terms-and-conditions"
                      rules="required"
                    >
                      <b-form-checkbox
                        id="terms-and-conditions"
                        v-model.trim="preregistered"
                        name="terms-and-conditions"
                        :state="errors.length > 0 ? false : null"
                        :disabled="disableInputs"
                      >
                      {{$t("preregistered_company_payment")}}
                      </b-form-checkbox>
                      <small class="text-danger">{{ $t(errors[0]) }}</small>
                    </validation-provider>
                    <div
                      style="background: #efefef; padding: 10px"
                      class="p-1 mt-1"
                    >
                      <p>
                        {{ $t("preregistered_company_text") }}
  
                      </p>
                    </div>
                  </b-form-group>
                 
                </b-col>
              </b-row>
            </b-card>
          <h5>{{ $t("legal_notice") }}</h5>
          <b-card>
            <b-row m-0 p-0>
              <b-col cols="12">
                <!-- terms-and-conditions -->
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="terms-and-conditions"
                    rules="required"
                  >
                    <b-form-checkbox
                      id="terms-and-conditions"
                      v-model.trim="terms_and_conditions"
                      name="terms-and-conditions"
                      :state="errors.length > 0 ? false : null"
                      :disabled="disableInputs"
                    >
                      {{
                        $t("agree_with_terms_&_conditions_seller_before_link")
                      }}

                      <span class="web">
                        <a @click="toTerms()">{{
                            $t("agree_with_terms_&_conditions_seller_link")
                          }}</a>
                      </span>
                      <span class="mobile">
                          <a @click="toTerms()">{{
                            $t("agree_with_terms_&_conditions_seller_link")
                          }}</a>
                        </span>
                    </b-form-checkbox>
                    <small class="text-danger">{{ $t(errors[0]) }}</small>
                  </validation-provider>
                  <div style="background: #efefef; padding: 10px" class="mt-1">
                    <p>
                      {{ $t("agree_with_terms_&_conditions_seller_subtitle") }}

                      <!-- Diese AGB's gelten. Wir machen Sie insbesondere darauf aufmerksam, dass Falschangaben strafrechtlich geahndet werden konnen. Auf folgenden Tatbestande kann die Haftung auf Sie personlich rubergehen. -->
                    </p>
                    <p class="col-md-12">
                      {{ $t("agree_with_terms_&_conditions_seller_1") }}

                      <!-- Diese AGB's gelten. Wir machen Sie insbesondere darauf aufmerksam, dass Falschangaben strafrechtlich geahndet werden konnen. Auf folgenden Tatbestande kann die Haftung auf Sie personlich rubergehen. -->
                    </p>
                    <p class="col-md-12">
                      {{ $t("agree_with_terms_&_conditions_seller_2") }}
                    </p>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
       
          <b-row m-0 p-0 class="col-12 text-end justify-content-end" align="end">
            <div class="mr-2">
              <b-link class="btn btn-outline-primary" to="/">
           <FeatherIcon icon="ChevronLeftIcon"></FeatherIcon>
           {{ $t("back_to_dashboard") }}
         </b-link>
        </div>
            <div  class="text-right" v-if="!disableInputs">
              <b-button variant="primary" @click="saveCompanyInfo()">
                <feather-icon icon="SaveIcon"></feather-icon>
                {{ $t("edit_information") }}
              </b-button>
            </div>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Cleave from "vue-cleave-component";
import { phone } from "phone";
// import { mapGetters } from 'vuex/types/helpers';
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  data() {
    return {
      showLoader: false,
      disableInputs: false,
      country_id: 1,
      countries: [],
      countries_de: [],
      countries_code: [],
      company_id: this.$store.state.auth.user.company_id,
      company_name: null,
      companies: [],
      company_zefix_data: null,
      company_address: null,
      company_address_nr: null,
      company_uid: null,
      company_zip: null,
      company_city: null,
      company_legal_form: null,
      company_website: null,
      showTransactions: false,
      legal_forms: [
        { value: 0,text: this.$t("sole_proprietorship")},
        { value: 1, text: this.$t("general_partnership")},
        { value: 2, text: this.$t("limited_partnership")},
        { value: 3, text: this.$t("corporation")},
        { value: 4, text: this.$t("limited_liability_company")},
        { value: 5,  text: this.$t("cooperative")}
      ],
      company_established_at: null,
      number_of_employees: 0,
      annual_turnover: 0,
      intent_to_sell: "1% - 10%",
      number_of_debtors: "1-10",
      main_contact_id: "",
      debt_enforcement: "Yes",
      intent_to_sell_values: [
        "1% - 10%",
        "11% - 20%",
        "21% - 30%",
        "31% - 50%",
        "51% - 70%",
        "71% - 80%",
        "81% - 90%",
        "91% - 100%",
      ],
      number_of_debtors_values: [
        "1-10",
        "11-20",
        "21-50",
        "51-100",
        "101-200",
        "201-500",
        "500+",
      ],
      debt_enforcement_values: ["Yes", "No"],
      main_contact_gender: "Mr",
      main_contact_email: this.$store.state.auth.user.email,
      main_contact_first_name: this.$store.state.auth.user.name,
      main_contact_last_name: this.$store.state.auth.user.last_name,
      main_contact_tel: null,
      main_contact_position: null,
      signing_authority_type: "Collectively by two",
      errors: [],
      signing_authority_type_values: ["Sole signatory", "Collectively by two"],

      signators: [],
      terms_and_conditions: "",
      data_privacy: "",
      deletedSignators: "",
      hideSigners: true,
      disabled: false,
      bank_name: "",
      bank_address: "",
      iban: "",
      reference_number: "",
      signators1: "",
      language: "",
      phoneSignatorValidation: "",
      phoneList: [],
      phoneValidation: "",
      phoneContries: "CH",
      phoneOptions: {
        phone: {
          delimiters: [" ", " ", " "],
          blocks: [2, 3, 2, 2],
        },
      },
      preregistered: false,
    };
  },
  name: "AddCompany",
  created() {
    this.$http.post("/countries").then((res) => {
      res.data.value.map((item) => {
        this.countries_code.push(item);
        this.countries.push({
          value: item.id,
          text: item.name,
        });
        this.countries_de.push({
          value: item.id,
          text: item.name_de,
        });

        this.phoneList.push({
          value: item.iso_code,
          text: `${item.iso_code} (${item.phone_code})`,
        });
      });
    });
    if (this.company_id) {
      this.$http.post(`/company/show/${this.company_id}`).then((res) => {
        if (res.data.errors && res.data.errors.length > 0) {
          this.errors = res.data.errors;
        }
        if (res && res.data && res.data.value) {
          let response = res.data.value;
          this.disableInputs = response.status == 1 ? false : true;
          this.company_name = response.name;
          this.company_address = response.address;
          this.company_address_nr = response.address_nr;
          this.company_uid = response.uid;
          this.company_zip = response.zip;
          this.company_city = response.city;
          this.country_id = response.country_id;
          this.company_website = response.website;
          this.main_contact_gender = response.main_contact.gender;
          this.main_contact_first_name = response.main_contact.name;
          this.main_contact_last_name = response.main_contact.last_name;
          this.main_contact_position = response.main_contact.position;
          this.main_contact_tel = this.getPhone(
            response.main_contact.iso_code,
            response.main_contact.tel
          );
          this.signators = response.signators;
          this.signators1 = response.signators;
          this.data_privacy = response.data_privacy == 1 ? true : false;
          this.placeholder = response.placeholder;
          this.main_contact_id = response.main_contact.id;
          this.terms_and_conditions =
            response.terms_and_conditions == 1 ? true : false;
          this.company_legal_form = response.legal_form;
          this.company_legal_form = response.legal_form;
          // this.bank_name = response.bank_name;
          // this.bank_address = response.bank_address;
          // this.iban = response.iban;
          this.language = response.language;
          this.phoneContries = response.main_contact.iso_code;
          if (response.financial_data) {
            this.number_of_employees =
              response.financial_data.number_of_employees;
            this.annual_turnover = response.financial_data.annual_turnover;
            this.company_established_at =
              response.financial_data.established_since;
            this.number_of_debtors = response.financial_data.number_of_debtors;
            this.debt_enforcement =
              response.financial_data.debt_enforcement == 1 ? "Yes" : "No";
            this.signing_authority_type =
              response.main_contact_signing_authority_type;
            this.intent_to_sell = response.financial_data.intent_to_sell;
          }
          this.$http
          .post("/get-company-data-from-zefix", {
            search: response.uid
          })
          .then((res) => {
            if (res.data.value) {
             this.preregistered = res.data.value.preregistered
            }
          });

        }
      });
    }
  },
  computed: {},
  methods: {
    toTerms(){
      if(this.$i18n.locale == "en"){
        window.open("https://www.aequitex.com/terms-condition", "_blank");
      }
      else{
        window.open("https://www.aequitex.com/de/bedingungen-und-konditionen", "_blank");
      }
      },
    checkPhoneFormatSignator(val, code) {
      if (val) {
        let validation = phone(val, {
          country: code,
          validateMobilePrefix: false
        });
        if (validation && validation.isValid == false) {
          this.phoneSignatorValidation = `Phone for ${code} must be valid`;
        } else {

          this.phoneSignatorValidation = "";
          this.val = this.getPhone(code, val);
        }
      }
    },
    checkPhoneFormat() {
      let validation = phone(this.main_contact_tel, {
        country: this.phoneContries,
        validateMobilePrefix: false
      });

      if (validation && validation.isValid == false) {
        this.phoneValidation = `Phone for ${this.phoneContries} must be valid`;
      } else {
        this.phoneValidation = "";
      }
    },
    toggleDarkMode() {
      let color = "";
      if (this.$store.state.verticalMenu.lightMode == "light") {
        color = "background:white";
      } else {
        color = "background:#283046";
      }
      return color;
    },
    hideSignersPart() {
      this.signators = this.signators1;
      if (this.signing_authority_type == "0") {
        this.hideSigners = false;
        this.signators = [];
      } else if (
        this.signing_authority_type != "0" &&
        this.signators.length < 1
      ) {
        this.signators.push({
          gender: "Mr",
          first_name: "",
          email: "",
          last_name: "",
          tel: "",
          position: "",
          signing_authority_type: "",
          iso_code: "",
          signer: {
            signing_authority_type: null,
          },
        });
        this.hideSigners = true;
      }
    },
    onInputChange(text) {
      if (text === "" || text === undefined) {
        return;
      }
      this.$http
        .post("/get-company-data-from-zefix", {
          search: this.companies[this.company_name],
        })
        .then((res) => {});
      const filteredOptions = this.datasuggest.data[0].designers
        .filter(
          (item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        )
        .slice(0, this.limit);
    },
    saveCompanyInfo() {
      this.$refs.companyRegisterValidation.validate().then((success) => {
        if (this.terms_and_conditions) {
          if (success && this.phoneValidation.length < 1) {
            this.disableInputs = true;
            const formSubmissionData = {
              company_id: this.company_id,
              company_name: this.company_name,
              company_address: this.company_address,
              company_address_nr: this.company_address_nr,
              company_uid: this.company_uid,
              company_zip: this.company_zip,
              company_city: this.company_city,
              company_country_id: this.country_id,
              company_website: this.company_website,
              main_contact_person_gender: this.main_contact_gender,
              main_contact_id: this.main_contact_id,
              main_contact_email: this.main_contact_email,
              main_contact_first_name: this.main_contact_first_name,
              main_contact_last_name: this.main_contact_last_name,
              main_contact_position: this.main_contact_position,
              main_contact_tel: this.main_contact_tel,
              signing_authority_type: this.signing_authority_type,
              established_at: this.company_established_at,
              number_of_employees: Number(this.number_of_employees),
              annual_turnover: Number(this.annual_turnover),
              intent_to_sell: this.intent_to_sell,
              number_of_debtors: this.number_of_debtors,
              debt_enforcement: this.debt_enforcement,
              signers: this.signators.length,
              terms_and_conditions: this.terms_and_conditions,
              data_privacy: this.data_privacy,
              placeholder: 1,
              deleted_signators: this.deletedSignators,
              legal_form: this.company_legal_form,
              // bank_name: this.bank_name,
              // bank_address: this.bank_address,
              // iban: this.iban,
              iso_code: this.phoneContries,
              preregistered: this.preregistered,
            };
            let data = {};
            if (this.signators.length > 0) {
              this.signators.map((item, i) => {
                const nr = i + 1;
                const signator = {
                  ["signator_" + nr + "_id"]: item.id,
                  ["signator_" + nr + "_gender"]: item.gender,
                  ["signator_" + nr + "_first_name"]: item.name,
                  ["signator_" + nr + "_last_name"]: item.last_name,
                  ["signator_" + nr + "_email"]: item.email,
                  ["signator_" + nr + "_tel"]: item.tel,
                  ["signator_" + nr + "_iso_code"]: item.iso_code,
                  ["signator_" + nr + "_position"]: item.position,
                  ["signator_" + nr + "_signing_authority_type"]:
                    item.signer.signing_authority_type,
                };
                data = { ...data, ...signator };
              });
              data = { ...data, ...formSubmissionData };
            } else {
              data = formSubmissionData;
            }
            this.$http
              .post(`/company/update/${this.company_id}`, data)
              .then((res) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Company updated successfully",
                    icon: "SuccessIcon",
                    variant: "success",
                  },
                });
                this.$http.post("/company/update/language", {
                  language: this.language,
                });
                if (res.data.errors && res.data.errors.length > 0) {
                  this.errors = res.data.errors;
                }
              });
          }
          this.disableInputs = false;
        } else {
        }
      });
    },
    deleteSignator(index, item) {
      this.signators.splice(index, 1);
      if (item.id) {
        this.deletedSignators += item.id + ",";
      }
    },
    searchForCompaniesName() {
      if (this.company_name.length > 2) {
        this.$http
          .post("/get-companies-name-from-zefix", { search: this.company_name })
          .then((res) => {
            this.filteredOptions = res.data.value;
          });
      }
    },
    getPhone(iso, tel) {
      if (this.countries_code && this.countries_code.length) {
        this.countries_code.map((i, item) => {
          if (i.iso_code == iso) {
            tel = tel.replace(i.phone_code, "");
          }
        });
      }
      return tel;
    },
    getCompanyDataFromZefix() {
      this.showLoader = true;
      if (typeof this.companies[this.company_name] != "undefined") {
        this.$http
          .post("/get-company-data-from-zefix", {
            search: this.companies[this.company_name],
          })
          .then((res) => {
            this.company_zefix_data = res.data.value;
            this.company_uid = this.company_zefix_data.uid;
            this.company_address = this.company_zefix_data.address?.street;
            this.company_address_nr =
              this.company_zefix_data.address.houseNumber;
            this.company_city = this.company_zefix_data.address.city;
            this.company_zip = this.company_zefix_data.address.swissZipCode;
            this.company_legal_form = this.company_zefix_data.legalForm.name.de;
            this.company_established_at = this.company_zefix_data.sogcDate;
            this.showLoader = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Data received successfully",
                icon: "SuccessIcon",
                variant: "success",
              },
            });
          });
      }
    },
    addNewSigner() {
      if (this.signators.length < 5) {
        this.signators.push({
          gender: "Mr",
          first_name: null,
          email: null,
          last_name: null,
          tel: null,
          position: null,
          iso_code: "CH",
          signing_authority_type: null,
          signer: {
            signing_authority_type: null,
          },
        });
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "You can add only up to 5 Signatory!",
            icon: "ErrorIcon",
            variant: "error",
          },
        });
      }
    },
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(event) {
      event.preventDefault();
      this.country_id = 0;
      this.countries = [];
      this.countries_de = [];
    },
  },
};
</script>

<style>
</style>
